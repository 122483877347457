<template>
  <div class="page-box">
    <div class="meeting-box">
      <img style="width:52%;margin-top: 750px;" src="../../assets/images/liveTitle1.png"/>
      <div class="">
        <div class="liveBox" v-for="(item,index) in list1" :key="index">
          <div class="flex-r-c" style="margin-bottom:10px">
            <img style="width:50px" src="../../assets/images/time.png"/>
            <span class="liveText">{{item.date}}</span>
          </div>
          <div class="modle-box">
            <a v-for="(obj,cindex) in item.child" :key="cindex" class="liveBox-bottom" :href="obj.url" target="_blank" :style="{opacity: obj.time? 1: 0}">
              <div class="liveText-left">
                <div class="liveText1 liveText-sp">
                  <div>{{item.date}}</div>
                  <div style="font-size:16px;margin-top:10px;letter-spacing:2px;">{{obj.time}}</div>
                </div>
                <div class="liveText1" :style="{'font-size': `${obj.fontSize}px`}" style="width:72%">{{obj.title}}</div>
              </div>
              <div class="palyBt">
                <img style="width:45px" src="../../assets/images/play.png"/>
              </div>
            </a>
          </div>
        </div>
      </div>
      <img style="width:52%;margin-top: 60px;" src="../../assets/images/liveTitle2.png"/>
      <div class="" style="margin-bottom:90px">
        <div class="liveBox" v-for="(item,index) in list2" :key="index">
          <div class="flex-r-c" style="margin-bottom:10px">
            <img style="width:50px" src="../../assets/images/time.png"/>
            <span class="liveText">{{item.date}}</span>
          </div>
          <div class="modle-box">
            <a v-for="(obj,cindex) in item.child" :key="cindex" class="liveBox-bottom" :href="obj.url" target="_blank" :style="{opacity: obj.time? 1: 0}">
              <div class="liveText-left">
                <div class="liveText1 liveText-sp">
                  <div>{{item.date}}</div>
                  <div style="font-size:16px;margin-top:10px;letter-spacing:2px;">{{obj.time}}</div>
                </div>
                <div class="liveText1" :style="{'font-size': `${obj.fontSize}px`}" style="width:72%">{{obj.title}}</div>
              </div>
              <div class="palyBt">
                <img style="width:45px" src="../../assets/images/play.png"/>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["language"],
  data() {
    return {
      list1: [
        {
          date: '10月25日',
          child: [
            {time: '18:00-20:30',title:'中国卒中学会青年理事会& \xa0上海卒中学会联合直播间',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=lj7781al'},
            {},
            // {time: '18:00-20:30',title:'上海卒中学会&中国卒中学会青年理事会 联合直播间',fontSize:24},
            // {time: '13:00—18:00',title:'上海卒中学会心血管内科分会第二届年会开幕式',fontSize:24},
          ]
        },
        {
          date: '10月26日',
          child: [
            {time: '13:00-18:20',title:'上海卒中学会心血管内科分会第二届年会开幕式',fontSize:23,url:'https://live-one.tscloud-tech.com/live/watch?id=mxj2rx4o'},
            {time: '18:00-20:30',title:'江西省卒中学会&上海卒中学会联合直播间',fontSize:22,url:'https://live-one.tscloud-tech.com/live/watch?id=ogpp9dko'},
            {time: '18:00-20:30',title:'江苏省卒中学会&上海卒中学会联合直播间',fontSize:22,url:'https://live-one.tscloud-tech.com/live/watch/mobile?id=obvvb80m'},
          ]
        },
        {
          date: '10月27日',
          child: [
            {time: '09:00-18:40',title:'上海卒中学会心血管内科分会第二届年会左心耳封堵论坛',fontSize:23,url:'https://live-one.tscloud-tech.com/live/watch?id=odd7nk0o'},
            {time: '18:00-20:30',title:'浙江省卒中学会&上海卒中学会联合直播间',fontSize:22,url:'https://live-one.tscloud-tech.com/live/watch?id=mxjj2d4o'},
            {time: '09:00—13:55',title:'上海卒中学会心血管内科分会第二届年会影像论坛',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=me317p1o'},
            {time: '18:00-20:30',title:'广东省卒中学会&上海卒中学会联合直播间',fontSize:22,url:'https://live-one.tscloud-tech.com/live/watch?id=o6jjdyel'},
            {time: '14:00—19:10',title:'上海卒中学会心血管内科分会第二届年会房颤论坛',fontSize:23,url:'https://live-one.tscloud-tech.com/live/watch?id=lrx61npl'},
          ]
        },
        {
          date: '10月28日',
          child: [
            {time: '09:00-12:30',title:'上海卒中学会心血管内科分会第二届年会冠脉介入论坛',fontSize:23,url:'https://live-one.tscloud-tech.com/live/watch?id=mnb1yg7m'},
            {time: '18:00-20:30',title:'大咖直通车',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=o5662a0l'},
            {time: '09:00-13:00',title:'上海卒中学会心血管内科分会第二届年会动脉粥样硬化论坛',fontSize:23,url:'https://live-one.tscloud-tech.com/live/watch?id=lrx61nal'},
            {},
            {time: '14:00-18:00',title:'上海卒中学会心血管内科分会第二届年会血栓学论坛',fontSize:23,url:'https://live-one.tscloud-tech.com/live/watch?id=o9r2715o'},
            {},
            {time: '14:00-19:30',title:'上海卒中学会心血管内科分会第二届年会高血压论坛',fontSize:23,url:'https://live-one.tscloud-tech.com/live/watch?id=l89pnv5m'},
          ]
        },
      ],
      list2:[
        {
          date: '10月29日',
          child: [
            {time: '08:30-12:15',title:'急性脑梗死在关注治疗质量改进国家行动区域培训会-再灌注主料培训',fontSize:18,url:'https://live-one.tscloud-tech.com/live/watch?id=m4y716em'},
            {time: '13:30-16:30',title:'急性脑梗死在关注治疗质量改进国家行动区域培训会-静脉溶栓专题培训',fontSize:18,url:'https://live-one.tscloud-tech.com/live/watch?id=o9r2366o'},
            {},
            {time: '13:30-16:30',title:'急性脑梗死在关注治疗质量改进国家行动区域培训会-血管内治疗专题培训',fontSize:18,url:'https://live-one.tscloud-tech.com/live/watch?id=lrx6d26l'},
            {time: '09:30-12:00',title:'上海卒中学会第二届学术年会',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=m3yn3abo'},
            {},
            {time: '14:00-17:10',title:'上海卒中学会青年理事会第二届学术会议',fontSize:23,url:'https://live-one.tscloud-tech.com/live/watch?id=o6jdgpzl'},
            {time: '14:00-17:20',title:'上海卒中学会中西医结合分会第二届学术会议',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=o06287jo'},
            {time: '14:00-17:30',title:'上海卒中学会神经内科分会第二届学术会议',fontSize:25,url:'https://live-one.tscloud-tech.com/live/watch?id=o562bknl'},
            {time: '14:00-16:30',title:'上海卒中学会护理分会第二届学术会议',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=m2p67gdl'},
            {time: '14:00-17:05',title:'上海卒中学会心血管分会第二届学术会议',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=lk72ybyo'},
            {time: '14:00-17:40',title:'中国卒中中心联盟（上海）论坛',fontSize:22,url:'https://live-one.tscloud-tech.com/live/watch?id=m4y7abkm'},
          ]
        },
        {
          date: '10月30日',
          child: [
            {time: '08:50-12:10',title:'第十五届中国神经病学论坛',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=mv72vjjm'},
            {},
            {time: '14:30-18:10',title:'神经病学国内外进展论坛',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=o9r206no'},
            {time: '14:30-17:40',title:'神经系统免疫与罕少见病分论坛',fontSize:22,url:'https://live-one.tscloud-tech.com/live/watch?id=lrx602pl'},
            {time: '14:00-17:40',title:'认知障碍与脑疾病及心理分论坛',fontSize:22,url:'https://live-one.tscloud-tech.com/live/watch?id=oydgnpyo'},
            {time: '14:30-17:40',title:'神经影像与神经病理分论坛',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=l89pkgvm'},
            {time: '14:30-17:40',title:'癫痫分论坛',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=me318k1o'},
            {time: '14:30-17:40',title:'头痛与眩晕分论坛',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=mnb1xr7m'},
            {time: '14:00-18:00',title:'帕金森与运动障碍分论坛',fontSize:24,url:'https://live-one.tscloud-tech.com/live/watch?id=m4y7a2ym'},
            {},
          ]
        },
        {
          date: '10月31日',
          child: [
            {time: '09:00-12:15',title:'第二届神经系统疾病·泛长三角论坛',fontSize:24,url:''},
          ]
        },
      ]
    };
  },
};
</script>

<style lang='less'>
.page-box {
  width: 100%;
  min-width: 1270px;
  aspect-ratio: 1903/1372;
  height: 100%;
  background-image: url("../../assets/images/newBg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 15.7%;
    box-sizing: border-box;
  .meeting-box {
      width: 63.02%;
      min-width: 1270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    box-sizing: border-box;
  }
  .liveBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .flex-r-c {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .liveText {
    margin-left:5px;
    letter-spacing: 3px;
    color: #142579;
    font-weight: bold;
    font-size: 28px;
  }
  .liveText1 {
    margin-left:5px;
    letter-spacing: 3px;
    color: #142579;
    font-weight: bold;
    font-size: 24px;
  }
  .liveText-sp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:26%;
    margin-right: 15px;
  }
  .liveText-left {
    margin-bottom:30px;
    width: 88%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .liveBox-bottom {
    background-image: url("../../assets/images/liveBg.jpg");
    background-size: 100% 100%;
    padding: 5px;
    width: calc(50% - 30px);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 30px;
    margin-top: 20px;
  }
  .palyBt {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .modle-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
</style>